<template>
  <div class="container">
    <Messages />
    <Advertorials />
    <KeywordCheck v-if="isAllowedToSearchKeywords" />
  </div>
</template>

<script>
// @ is an alias to /src
import Messages from "@/components/overview/Messages";
import Advertorials from "@/components/overview/Advertorials";
import KeywordCheck from "@/components/overview/KeywordCheck.vue";
import { mapGetters } from "vuex";

export default {
  name: "Home",
  components: {
    KeywordCheck,
    Advertorials,
    Messages,
  },
  computed: {
    ...mapGetters("user", {
      isAllowedToSearchKeywords: "getIsAllowedToSearchKeywords",
    }),
  },
};
</script>
