<template>
  <form class="keyword-check mt--8" @submit.prevent="onFormSubmit">
    <h3 class="headline headline--section">
      {{ labels?.keywordCheck?.headline }}
    </h3>
    <p class="mt--1" v-html="labels?.keywordCheck?.copy"></p>
    <div class="grid-row mt--2">
      <div class="col-5">
        <BaseTextInput
          :placeHolder="labels?.keywordCheck?.form?.placeholder"
          icon="search"
          v-model="query"
          :validator="v$.query"
          class="form-field--filter"
        />
      </div>
      <div class="col-3">
        <BaseButton type="submit" :text="labels?.keywordCheck?.form.submit" />
      </div>
      <div class="col-8">
        <table v-if="results?.length" class="keyword-check__table mt--2">
          <thead>
            <tr>
              <td
                v-for="column in tableColumns"
                :key="`advertorials-thead-td-${column}`"
              >
                {{ labels?.keywordCheck?.table?.[column] }}
              </td>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="result in results || []"
              :key="`advertorials-tr-${result.keyword}-${result.publisher}`"
            >
              <td>{{ result.keyword }}</td>
              <td>{{ config.publisher?.[result.publisher]?.name }}</td>
              <td>
                {{
                  result.validTo
                    ? toDateString(result.validTo)
                    : labels?.keywordCheck?.table?.uncancelled
                }}
              </td>
            </tr>
          </tbody>
        </table>
        <InfoTile
          v-else-if="results"
          tile-type="success"
          :text="zeroResultsMessage"
          class="mt--2"
        />
      </div>
    </div>
  </form>
</template>
<script>
import BaseTextInput from "@/components/form/BaseTextInput.vue";
import BaseButton from "@/components/elements/BaseButton.vue";
import { mapState } from "vuex";
import axiosInstance from "@/utils/axiosInstance";
import formatStringMixin from "@/mixins/formatStringMixin";
import InfoTile from "@/components/overview/InfoTile.vue";
import useVuelidate from "@vuelidate/core";
import { required, minLength } from "@vuelidate/validators";

export default {
  name: "KeywordCheck",
  components: { InfoTile, BaseButton, BaseTextInput },
  mixins: [formatStringMixin],
  props: {
    tableColumns: {
      type: Array,
      default() {
        return ["keyword", "publisher", "validTo"];
      },
    },
  },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  data() {
    return {
      query: "",
      submittedQuery: "",
      results: null,
    };
  },
  computed: {
    ...mapState("common", ["config", "labels"]),
    zeroResultsMessage() {
      return this.labels?.keywordCheck?.zeroResults.replace(
        "%KEYWORD%",
        `"${this.submittedQuery}"`
      );
    },
  },
  methods: {
    async onFormSubmit() {
      const isValid = !this.v$ || (await this.v$.$validate());
      if (isValid) {
        try {
          const result = await axiosInstance.get(`keywords/${this.query}`);
          this.submittedQuery = this.query;
          this.results = result.data;
        } catch (e) {
          console.warn("KeywordCheck onFormSubmit failed ", e);
        }
      }
    },
  },
  validations() {
    return {
      query: {
        required,
        minLength: minLength(4),
      },
    };
  },
};
</script>

<style scoped lang="stylus">

.keyword-check {

  table-default()

}
</style>
