<template>
  <div v-if="advertorial" class="container">
    <div class="grid-row">
      <div class="col-8">
        <h1 class="headline headline--main mt--8">{{ advertorial.title }}</h1>
        <TabNav />
        <p class="mt--5">{{ componentLabels.description }}</p>
        <p class="mt--2 fw--bold">{{ componentLabels.legalNote }}</p>
        <div
          v-for="(badge, badgeKey) in badges"
          :key="`badge-${advertorialId}-${badgeKey}`"
          class="mt--8"
        >
          <h2 class="headline headline--section">
            {{ badge.title }}
          </h2>
          <img
            :src="badge.imageSrc"
            :alt="badge.imageAlt"
            class="d--block mt--2"
          />
          <CopyField
            :copy-string="badge.code"
            :label="componentLabels.codeTitle"
            :copy-success-message="componentLabels.copySuccessMessage"
            class="mt--3"
          />
        </div>
        <Feedback
          :advertorialId="advertorialId"
          :advertorialStatus="advertorial?.advertorialStatus"
          :showFinalizeFeedbackButton="showFinalizeFeedbackButton"
        />
      </div>
      <div class="col-4 mt--4">
        <StatusBox />
      </div>
    </div>
  </div>
</template>

<script>
import advertorialMixin from "@/mixins/advertorialMixin";
import TabNav from "@/components/advertorial/TabNav";
import StatusBox from "@/components/advertorial/StatusBox";
import { mapState } from "vuex";
import CopyField from "@/components/advertorial/CopyField";
import Feedback from "@/components/advertorial/Feedback.vue";

export default {
  name: "Badge",
  components: {
    Feedback,
    CopyField,
    TabNav,
    StatusBox,
  },
  mixins: [advertorialMixin],
  computed: {
    ...mapState("common", ["labels"]),
    componentLabels() {
      return this.labels?.advertorial?.badge || {};
    },
    badges() {
      return {
        l: {
          title: this.componentLabels?.titleLVersion,
          imageSrc: `${process.env.BASE_URL}/images/badges/badge_${this.advertorial?.publisher}_l.png`,
          imageAlt: `Badge - ${this.advertorial?.publisher} - ${this.componentLabels?.titleLVersion}`,
          code: this.advertorial?.badges?.l,
        },
        m: {
          title: this.componentLabels?.titleMVersion,
          imageSrc: `${process.env.BASE_URL}/images/badges/badge_${this.advertorial?.publisher}_m.png`,
          imageAlt: `Badge - ${this.advertorial?.publisher} - ${this.componentLabels?.titleMVersion}`,
          code: this.advertorial?.badges?.m,
        },
        s: {
          title: this.componentLabels?.titleSVersion,
          imageSrc: `${process.env.BASE_URL}/images/badges/badge_${this.advertorial?.publisher}_s.png`,
          imageAlt: `Badge - ${this.advertorial?.publisher} - ${this.componentLabels?.titleSVersion}`,
          code: this.advertorial?.badges?.s,
        },
      };
    },
  },
};
</script>

<style scoped></style>
