import axiosInstance from "@/utils/axiosInstance";

const getDefaultState = () => {
  return {
    user: {},
    currentTeam: null,
  };
};

const state = getDefaultState();

const getters = {
  getCurrentTeam(state) {
    return state.currentTeam ?? state.user?.teams?.[0];
  },
  getLoginStatus(state) {
    return Boolean(state.user?.id);
  },
  getIsAllowedToSearchKeywords(state) {
    return state.user?.isAllowedToSearchKeywords;
  },
};

const actions = {
  async fetchUser({ commit }) {
    try {
      const result = await axiosInstance.get("customer");
      commit("SET_USER", result.data);
    } catch (e) {
      console.warn("fetchUser failed", e);
    }
  },
  async login({ dispatch }, { username, password }) {
    dispatch("ui/toggleLockLayer", true, { root: true });
    try {
      const result = await axiosInstance.post("login", {
        username,
        password,
      });
      if (result.status === 204) {
        await dispatch("fetchUser");
        await dispatch("common/fetchConfig", null, { root: true });
        dispatch("ui/toggleLockLayer", false, { root: true });
      }
    } catch (e) {
      console.warn("login failed", e);
      dispatch("ui/toggleLockLayer", false, { root: true });
      return {
        errorMessage: e.response?.data?.message,
      };
    }
  },
  async setPassword({ dispatch }, { password, token }) {
    dispatch("ui/toggleLockLayer", true, { root: true });
    try {
      const result = await axiosInstance.post("onboarding/set-password", {
        token,
        newPassword: {
          first: password,
          second: password,
        },
      });
      if (result.status === 201 || result.status === 200) {
        dispatch("ui/toggleLockLayer", false, { root: true });
        return { success: true };
      }
    } catch (e) {
      dispatch("ui/toggleLockLayer", false, { root: true });
      console.warn("setPassword failed", e);
      return {
        errorMessage: e.response?.data?.message,
      };
    }
  },
  async setNewPassword({ dispatch }, { password, token }) {
    dispatch("ui/toggleLockLayer", true, { root: true });
    try {
      const result = await axiosInstance.post("customer/set-new-password", {
        token: token,
        newPassword: {
          first: password,
          second: password,
        },
      });
      if (result.status === 201 || result.status === 200) {
        dispatch("ui/toggleLockLayer", false, { root: true });
        return { success: true, message: result?.data?.message };
      }
    } catch (e) {
      dispatch("ui/toggleLockLayer", false, { root: true });
      console.warn("setNewPassword failed", e);
      return {
        errorMessage: e.response?.data?.message,
      };
    }
  },
  async resetPassword({ dispatch }, { email }) {
    dispatch("ui/toggleLockLayer", true, { root: true });
    try {
      const result = await axiosInstance.post(
        "customer/send-password-reset-code",
        {
          email: email,
        }
      );
      if (result.status === 201 || result.status === 200) {
        dispatch("ui/toggleLockLayer", false, { root: true });
        return { success: true };
      }
    } catch (e) {
      dispatch("ui/toggleLockLayer", false, { root: true });
      console.warn("resetPassword failed", e);
      return {
        errorMessage: e.response?.data?.message,
      };
    }
  },
  async changeTeam({ commit, state, dispatch }, teamId) {
    commit(
      "SET_CURRENT_TEAM",
      state.user?.teams?.find((team) => team.id === teamId)
    );
    await dispatch("advertorials/fetchAdvertorials", teamId, { root: true });
    await dispatch("messages/fetchTeamMessages", teamId, { root: true });
  },
  logout({ commit, dispatch }) {
    commit("RESET");
    dispatch("ui/toggleLockLayer", false, { root: true });
  },
};

const mutations = {
  SET_USER: (state, payload) => {
    state.user = payload;
  },
  SET_CURRENT_TEAM: (state, payload) => {
    state.currentTeam = payload;
  },
  RESET: (state) => {
    Object.assign(state, getDefaultState());
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
