<template>
  <div
    v-if="modelValue || !(readonly && hideReadonlyWhenEmpty)"
    class="form-field"
    :class="[
      { 'form-field--has-value': modelValue },
      { 'form-field--error': hasError },
    ]"
  >
    <label class="form-field__wrapper">
      <div v-if="label" class="form-field__label">
        {{ label }}
        <div v-if="isRequired" class="form-field__required">(Pflicht)</div>
      </div>
      <span class="form-field__input-wrapper">
        <input
          v-bind="inputAttributes"
          :type="type"
          :name="name"
          :value="modelValue"
          :placeholder="placeHolder"
          :readonly="readonly"
          class="form-field__input"
          :class="[
            { 'form-field__input--has-icon': icon },
            { 'form-field__input--readonly': readonly },
          ]"
          @input="$emit('update:modelValue', $event.target.value)"
          @focus="$emit('focus')"
          @blur="$emit('blur')"
        />
        <SvgIcon v-if="icon" :icon="icon" class="form-field__icon" />
      </span>
      <span v-if="errorMessage" class="form-field__error-message"
        >{{ errorMessage }}
      </span>
    </label>
  </div>
</template>

<script>
import SvgIcon from "@/components/common/SvgIcon";
import { mapState } from "vuex";

export default {
  name: "BaseTextInput",
  components: { SvgIcon },
  props: {
    icon: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    modelValue: {
      type: String,
      default: null,
    },
    name: {
      type: String,
      default: null,
    },
    placeHolder: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: "text",
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    hideReadonlyWhenEmpty: {
      type: Boolean,
      default: true,
    },
    validator: {
      type: Object,
      default: null,
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:modelValue", "focus", "blur"],
  computed: {
    ...mapState("common", ["labels"]),
    componentLabels() {
      return this.labels?.formFields || {};
    },
    hasError() {
      return Boolean(this.validator?.$errors?.length);
    },
    errorMessage() {
      if (this.hasError) {
        const validatorName = this.validator?.$errors[0].$validator;
        const defaultMessage = this.validator?.$errors[0].$message;
        let customMessage =
          this.componentLabels?.errorMessages?.[validatorName] ||
          defaultMessage;

        console.log(this.validator?.$errors[0]);
        if (validatorName === "minLength") {
          const minLengthValue = this.validator?.$errors[0]?.$params?.min;
          customMessage = customMessage.replace("%MINLENGTH%", minLengthValue);
        }
        return customMessage;
      } else {
        return null;
      }
    },
    inputAttributes() {
      //TODO make this work through destructuring
      const attrs = { ...this.$attrs };
      attrs.class = null;
      attrs.style = null;
      return attrs;
    },
  },
};
</script>

<style lang="stylus">

.form-field {

  flex 1

  &__label {
    display flex
    align-items center
    flex-wrap wrap
    margin-bottom 8px
  }
  &__required{
    color: #9b9b9b;
    margin-left: 6px;
  }

  &__error-message {
    font-size: $font-size.xs
    color $color-form-error
    margin-top: 4px
    width 100%
    display block
  }
  &__error-warning{
    font-size: $font-size.xs
    color $color-anthracite
    margin-top: 4px
    width 100%
    display block
   }

  &__input-wrapper {
    display block
    position relative
  }

  &__input {
    display block
    border 1px solid $color-border-default
    background $color-background-form-field
    border-radius 2px
    padding-left 24px
    padding-right 24px
    font-size: $font-size.l
    width 100%
    height px-to-rem(60px)
    box-shadow none
    transition box-shadow $duration-transition-hover-default ease-in-out, color $duration-transition-hover-default ease-in-out

    &:hover, &:focus {
      box-shadow inset 0 0 0 2px $color-brand-green
    }

    &::placeholder {
      color $color-text-gray
    }

    &--readonly {
      border-color transparent
      background-color transparent
      box-shadow none !important
      padding 0
      pointer-events none
    }

    &--has-icon {
      padding-right 56px
    }

    ^[0]--error & {
      box-shadow inset 0 0 0 2px $color-form-error
      color $color-form-error
    }
  }

  &__icon {
    position absolute
    right 24px
    top 50%
    transform translateY(-50%)
    pointer-events none
  }

  &__info {
    color $color-text-gray
    font-size $font-size.xs
    width 100%

    &--error {
      color $color-form-error
    }
  }

  &--small {
    $label-padding-top = px-to-rem((40px - (14px * $line-height-default)) / 2)

    & ^[0]__wrapper {
      display flex
      align-items flex-start
    }

    & ^[0]__label {
      width 40%
      flex 0 0 auto
      padding-right 16px

      //center label vertical, can't be done with align-items because of additional space taken by error message
      // (form-field-height - (font-size * line-height) / 2)
      padding-top $label-padding-top
    }

    & ^[0]__input-wrapper {
      flex 1 1 auto
      min-width 0
      margin-top 0
    }

    & ^[0]__input,
    & ^[0]__textarea,
    & ^[0]__readonly-value {
      font-size $font-size.s
    }

    & ^[0]__readonly-value {
      padding-top $label-padding-top
      flex 1 1 100%
      min-width 0
    }

    & ^[0]__input,
    & ^[0]__textarea {
      padding-left 16px
      padding-right 16px
    }

    & ^[0]__input {
      height px-to-rem(40px)
    }
  }


  &--filter {
    & ^[0]--has-value {
      & ^[0]__input-wrapper {
        background-color $color-background-form-field
      }

      & ^[0]__input {
        background-color rgba($color-brand-green, 0.15)

        &:hover, &:focus {
          background-color transparent
        }
      }
    }
  }
}
</style>
